import Event from "./Event";

class EventGroup {
    name: string = "";
    year: number = 0;
    month: number = 0;
    events: Event[] = [];
    constructor(event : Event) {
        if(!event.startDate || !event.endDate)
        {
            // No start/end date, we just put it in a group by itself.
            this.name = "";
            this.events.push(event);
        } else {
            const currentDate = new Date();
            const dayAfterEvent = new Date(event.endDate.getTime() + 86400000);
            if(event.startDate < currentDate && dayAfterEvent > currentDate)
            {
                this.name = "Happening Now";
            } else {
                if(event.startDate.getFullYear() !== currentDate.getFullYear())
                {
                    this.name = event.startDate.toLocaleString('default', { month: 'long' }) + " " + event.startDate.getFullYear() || "";
                } else {
                    this.name = event.startDate.toLocaleString('default', { month: 'long' }) || "";
                }
            }
            this.year = event.startDate.getFullYear() || 0;
            this.month = event.startDate.getMonth() || 0;
            this.events.push(event);
        }
    }
}

export default EventGroup;