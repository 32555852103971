import Event from "../models/Event";

function IsEventInRegion(event: Event, region: string)
{
    if(region === "All Events") {
        return true;
    }
    if(region === "North America") {
        if(NorthAmericanCountries.includes(event.country))
        {
            return true;
        }
        return false;
    }
    if(region === "South America") {
        if(SouthAmericanCountries.includes(event.country))
        {
            return true;
        }
        return false;
    }
    if(region === "Europe") {
        if(EuropeanCountries.includes(event.country))
        {
            return true;
        }
        return false;
    }
    if(region === "Asia") {
        if(AsianCountries.includes(event.country))
        {
            return true;
        }
        return false;
    }
    if(region === "Oceania") {
        if(OceaniaCountries.includes(event.country))
        {
            return true;
        }
        return false;
    }
    if(region === "Southeast U.S.") {
        if(event.country === "United States"
            && SoutheastUsStates.includes(event.province))
        {
            return true;
        }
        return false;
    }
    if(region === "Northeast U.S.") {
        if(event.country === "United States"
            && NortheastUsStates.includes(event.province))
        {
            return true;
        }
        return false;
    }
    if(region === "Central U.S.") {
        if(event.country === "United States"
            && CentralUsStates.includes(event.province))
        {
            return true;
        }
        return false;
    }
    if(region === "Southwest U.S.") {
        if(event.country === "United States"
            && SouthwestUsStates.includes(event.province))
        {
            return true;
        }
        return false;
    }
    if(region === "Northwest U.S.") {
        if(event.country === "United States"
            && NorthwestUsStates.includes(event.province))
        {
            return true;
        }
        return false;
    }
    return false;
}

const NorthAmericanCountries = ["United States", "Canada", "Mexico"];
const SouthAmericanCountries = [
    "Argentina",
    "Brazil",
    "Chile",
    "Colombia",
    "Ecuador",
    "Peru"
];
const EuropeanCountries = [
    "Austria",
    "Belgium",
    "Bulgaria",
    "Croatia",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Estonia",
    "Finland",
    "France",
    "Germany",
    "Greece",
    "Hungary",
    "Ireland",
    "Italy",
    "Latvia",
    "Lithuania",
    "Luxembourg",
    "Malta",
    "Netherlands",
    "Poland",
    "Portugal",
    "Romania",
    "Slovakia",
    "Slovenia",
    "Spain",
    "Sweden",
    "United Kingdom"
];
const OceaniaCountries = ["Australia", "New Zealand"];
const AsianCountries = [
    "China",
    "India",
    "Indonesia",
    "Japan",
    "Malaysia",
    "Philippines",
    "Singapore",
    "South Korea",
    "Taiwan",
    "Thailand",
    "Vietnam"
];
const SoutheastUsStates = [
    "Alabama",
    "Arkansas",
    "Florida",
    "Georgia",
    "Kentucky",
    "Louisiana",
    "Mississippi",
    "North Carolina",
    "South Carolina",
    "Tennessee",
    "Texas",
    "Virginia",
];
const NortheastUsStates = [
    "Connecticut",
    "Delaware",
    "Indiana",
    "Illinois",
    "Kentucky",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "New Hampshire",
    "New Jersey",
    "New York",
    "Ohio",
    "Pennsylvania",
    "Virginia",
    "Vermont",
    "West Virginia",
    "Wisconsin",
];
const CentralUsStates = [
    "Arkansas",
    "Arizona",
    "Colorado",
    "Iowa",
    "Idaho",
    "Kansas",
    "Louisiana",
    "Minnesota",
    "Missouri",
    "Montana",
    "Nebraska",
    "North Dakota",
    "New Mexico",
    "Oklahoma",
    "South Dakota",
    "Texas",
    "Utah",
    "Wisconsin",
    "Wyoming",
];
const SouthwestUsStates = [
    "Arizona",
    "California",
    "Colorado",
    "Nevada",
    "New Mexico",
    "Oregon",
    "Utah",
];
const NorthwestUsStates = [
    "British Columbia",
    "Idaho",
    "Oregon",
    "Montana",
    "Nevada",
    "North Dakota",
    "South Dakota",
    "Utah",
    "Washington",
    "Wyoming",
];

export default IsEventInRegion;